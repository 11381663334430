export default function Personal() {
  return (
    <div className="roboto-regular">
      <ul className="list-disc pl-2 py-3 text-white">
        <li className="py-1 text-xl">
          Fitness app for live and recorded workouts
        </li>
        <li className="py-1 text-xl">Online B3 Community for motivation and inspiration</li>
        <li className="py-1 text-xl">Free first 30 days, then $49.99/m</li>
        <li className="py-1 text-xl">Invite 5 friends and your subscription is FREE</li>
      </ul>
    </div>
  );
}
