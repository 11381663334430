import { Fragment, useState, useContext, useRef, useEffect } from "react";
import { Listbox, Transition, Dialog } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { getCart, saveCart } from "../../utils/localstorage";
import { CartContext } from "../../contexts/CartContext";
import SizeChart from "./SizeChart";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import PRODUCTS from "../../../public/assets/products.json";
import Stepper from "../../components/Stepper";
import B3Member from "../../components/B3Member";

const skuConfigurations = [
  { Sku: "RCBandSt1", category: "1 SET", qty: 1, Image: "b3_bands_set.jpg" },
  {
    Sku: "RCBandSt2",
    category: "2 SETS",
    qty: 2,
    save: 80,
    Image: "b3_bands_set.jpg",
  },
  {
    Sku: "RCBandSt4",
    category: "4 SETS",
    qty: 4,
    save: 330,
    Image: "b3_bands_set.jpg",
  },
  {
    Sku: "RCBandSt10",
    category: "10 SETS",
    qty: 10,
    save: 1170,
    Image: "b3_bands_set.jpg",
  },
];

const productTypes = skuConfigurations.map((config) => {
  const product = PRODUCTS.find((p) => p.Sku === config.Sku);
  return {
    ...config,
    ProductName: product?.ProductName ?? "Unknown Product",
    Price: product?.Price ?? 0,
    Weight : product?.Weight
  };
});

const armBands = [
  {
    Sku: "Bands1",
    ProductName: "B3 Band Size 1",
    Description: "B3 Band Size 1",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands2",
    ProductName: "B3 Band Size 2",
    Description: "B3 Band Size 2",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const legBands = [
  {
    Sku: "Bands3",
    ProductName: "B3 Band Size 3",
    Description: "B3 Band Size 3",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands4",
    ProductName: "B3 Band Size 4",
    Description: "B3 Band Size 4",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
  {
    Sku: "Bands5",
    ProductName: "B3 Band Size 5",
    Description: "B3 Band Size 5",
    Image: "BandSets.png",
    ImageSmall: "BandSets-sm.png",
    Price: 0.0,
    Weight: 0.0,
  },
];

const additionalSize5 = {
  Sku: "RCSize5",
  ProductName: "B3 Bands Pair Size 5",
  Description: "",
  Image: "BandSets.png",
  ImageSmall: "BandSets-sm.png",
  Price: PRODUCTS.find((p) => p.Sku === "RCSize5").Price,
  Weight: 8.0,
};

const additionalItemSKUs = ["Pump1", "ExerTub1", "DuffleBag1"];
const additionalItems = additionalItemSKUs
  .map((sku) => PRODUCTS.find((product) => product.Sku === sku))
  .filter(Boolean);

export default function AddToCartForm({ onClose }) {
  const notify = () => toast("Product is added!");
  const [selected, setSelected] = useState(productTypes[0]);
  const [armSizes, setArmSizes] = useState([armBands[0]]);
  const [legSizes, setLegSizes] = useState([legBands[0]]);
  const cartContext = useContext(CartContext);
  const [currentStep, setCurrentStep] = useState(1);

  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleSetChange = (newSet) => {
    setSelected(newSet);
    setArmSizes(Array(newSet.qty).fill(armBands[0]));
    setLegSizes(Array(newSet.qty).fill(legBands[0]));
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentStep]);
  const addProduct = () => {
    const cart = getCart();
    notify();
    // Add ProdctType - SET
    const existing = cart.find((p) => p.product.Sku === selected.Sku);
    if (existing) {
      existing.qty = (Number(existing.qty) || 0) + 1;
    } else {
      cart.push({ product: selected, qty: 1 });
    }

    // Add individual items
    armSizes.concat(legSizes).forEach((product) => {
      const existing = cart.find((p) => p.product.Sku === product.Sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + 2;
      } else {
        cart.push({ product, qty: 2 });
      }
      if (product.Sku === "Bands5") {
        const existingAdditional = cart.find(
          (p) => p.product.Sku === "RCSize5"
        );
        if (existingAdditional) {
          existingAdditional.qty = (Number(existingAdditional.qty) || 0) + 1;
        } else {
          cart.push({ product: additionalSize5, qty: 1 });
        }
      }
    });

    // additional gear
    additionalItems.forEach((product) => {
      const existingItem = cart.find(
        (item) => item.product.Sku === product.Sku
      );
      if (existingItem) {
        existingItem.qty += selected.qty;
      } else {
        cart.push({ product, qty: selected.qty });
      }
    });

    cartContext.save(cart);
    setCurrentStep(2);
    // setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col mt-2">
      <div className="text-2xl md:text-4xl text-white roboto-regular">
        How many sets?
      </div>
      <div className="md:flex flex-row items-center gap-2 mt-1 md:mt-3">
        <Listbox value={selected} onChange={handleSetChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
              <span className="block truncate">{selected.category}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400 sm:text-2xl"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                {productTypes.map((type, typeIdx) => (
                  <Listbox.Option
                    key={typeIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                      }`
                    }
                    value={type}
                  >
                    {({ selected }) => (
                      <div className="flex flex-col text-base font-light">
                        <div>
                          <span
                            className={`block roboto-regular truncate ${
                              selected ? "font-normal" : "font-light"
                            }`}
                          >
                            {type.category}: ${type.Price}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-4 w-4 md:h-5 md:w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        {type.save && (
                          <div className="roboto-regular text-[#00adef] text-xs md:text-sm ">
                            *SAVE ${type.save}*
                          </div>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="flex gap-4 py-4">
        <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
          ${selected.Price}
        </div>
        <Button
          type="button"
          onClick={openModal}
          className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          ADD TO CART
        </Button>
      </div>

      <div className="flex gap-4">
        <div className="text-lg md:text-2xl text-white roboto-regular-italic my-auto">
          As low as $80 month with paypal credit with $0 down and 0% interest
        </div>
        <div className="flex flex-col ml-4">
          <img
            alt="paypal credit"
            src="./assets/images/paypal-credit-t.png"
            className="w-[170px] m-auto"
          />
        </div>
        {/* <img
          src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
          alt="PayPal Logo"
          width={65}
          className="border-0 rounded-full"
        /> */}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            if (currentStep === 1) {
              closeModal();
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  ref={contentRef}
                  className="lg:max-w-5xl max-w-3xl transform rounded-2xl bg-white px-10 pb-10 text-left align-middle shadow-xl transition-all sm:max-h-auto max-h-[700px] overflow-auto"
                >
                  <Stepper
                    currentStep={currentStep}
                    steps={[
                      <div key="step1">
                        {" "}
                        <Dialog.Title
                          as="h3"
                          className="text-xl pt-12 md:text-2xl font-medium leading-6 text-[#00adef]"
                        >
                          Size Selection
                        </Dialog.Title>
                        <div>
                          <div className="flex lg:flex-row flex-col justify-center">
                            <div className="flex flex-col overflow-x-visible mb-4 md:p-4 md:min-h-[400px] justify-start">
                              {Array.from(Array(selected.qty), (e, i) => {
                                return (
                                  <div key={i} className="mt-4">
                                    <div className="text-2xl capitalize text-[#00adef] mb-3">
                                      Set #{i + 1}
                                    </div>
                                    <div className="flex gap-2 flex-col sm:flex-row">
                                      <div className="flex flex-col">
                                        <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                          Arm Sizes
                                        </div>
                                        <Listbox
                                          value={armSizes[i]}
                                          onChange={(value) =>
                                            setArmSizes((prev) => {
                                              const newValue = [...prev];
                                              newValue[i] = value;
                                              return newValue;
                                            })
                                          }
                                        >
                                          <div className="relative mt-1">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                              <span className="block truncate">
                                                {armSizes[i].ProductName}
                                              </span>
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                  className="h-5 w-5 text-gray-400 sm:text-2xl"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>
                                            <Transition
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                                {armBands.map((band, idx) => (
                                                  <Listbox.Option
                                                    key={band.Sku}
                                                    className={({ active }) =>
                                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                        active
                                                          ? "bg-amber-100 text-amber-900"
                                                          : "text-gray-900"
                                                      }`
                                                    }
                                                    value={band}
                                                  >
                                                    <div className="flex flex-col text-base font-light">
                                                      <div>
                                                        <span
                                                          className={`block truncate ${
                                                            armSizes[i].Sku ===
                                                            band.Sku
                                                              ? "font-normal"
                                                              : "font-light"
                                                          }`}
                                                        >
                                                          {band.ProductName}
                                                        </span>
                                                        {armSizes[i].Sku ===
                                                        band.Sku ? (
                                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <CheckIcon
                                                              className="h-4 w-4 md:h-5 md:w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </Listbox.Option>
                                                ))}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </Listbox>
                                      </div>
                                      <div className="flex flex-col sm:mt-0 mt-3">
                                        <div className="text-base md:text-xl capitalize mb-1 md:mb-2">
                                          Leg Sizes
                                        </div>
                                        <Listbox
                                          value={legSizes[i]}
                                          onChange={(value) =>
                                            setLegSizes((prev) => {
                                              const newValue = [...prev];
                                              newValue[i] = value;
                                              return newValue;
                                            })
                                          }
                                        >
                                          <div className="relative mt-1">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                              <span className="block truncate">
                                                {legSizes[i].ProductName}
                                              </span>
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                  className="h-5 w-5 text-gray-400 sm:text-2xl"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>
                                            <Transition
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="md:absolute relative mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                                {legBands.map((band) => (
                                                  <Listbox.Option
                                                    key={band.Sku}
                                                    className={({ active }) =>
                                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                        active
                                                          ? "bg-amber-100 text-amber-900"
                                                          : "text-gray-900"
                                                      }`
                                                    }
                                                    value={band}
                                                  >
                                                    <div className="flex flex-col text-base font-light">
                                                      <div>
                                                        <span
                                                          className={`block truncate ${
                                                            legSizes[i].Sku ===
                                                            band.Sku
                                                              ? "font-normal"
                                                              : "font-light"
                                                          }`}
                                                        >
                                                          {band.ProductName}
                                                        </span>
                                                        {legSizes[i].Sku ===
                                                        band.Sku ? (
                                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                      {band.Sku ===
                                                        "Bands5" && (
                                                        <div className="text-[#00adef] text-xs md:text-sm">
                                                          Additional $30
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Listbox.Option>
                                                ))}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </Listbox>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="hidden sm:block min-w-[100px] flex justify-center ">
                              <SizeChart />
                            </div>
                            <div className="sm:hidden mx-auto w-full rounded-2xl shadow bg-white max-w-[250px]">
                              <Disclosure as="div" className="mt-2">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex w-full justify-between cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-2 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                                      <span className="text-md font-500 capitalize mb-1 md:mb-2">
                                        Size Chart
                                      </span>
                                      <ChevronUpIcon
                                        className={`${
                                          open ? "rotate-180 transform" : ""
                                        } h-5 w-5`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                      <SizeChart />
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                          <div className="flex justify-center mt-3 w-full">
                            <Button
                              type="button"
                              onClick={addProduct}
                              className="text-base md:text-lg px-5 py-2 rounded-full font-light mt-4 md:mt-0"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              ADD TO CART
                            </Button>
                          </div>
                        </div>
                      </div>,
                      <div key="step2">
                        {" "}
                        <B3Member />
                      </div>,
                    ]}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
